function renderTime(): void {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const timeString = `${hours}時${minutes}分`;
  document.getElementById('timeDisplay')!.textContent = timeString;
}

if (document.getElementById('timeDisplay')) {
  setInterval(renderTime, 30000); // 30秒ごとに更新
  renderTime(); // 初回の描画
}
